<template>
  <div v-if="custDetails !== null" class="mt-4">
    <div 
      class="card top_primary_card  bg-primary font-bold text-white p-2 rounded-xl  w-full relative"
    >
    <div class=" text-text2 heading-4 absolute left-3 mr-1 top-2 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="$router.go(-1)">
      <i class="fa-solid fa-arrow-left"></i>
    </div>
      <div class="flex justify-center items-center">
        <h1 class="heading-1 text-center">{{ custDetails.companyName }}</h1>
      </div>
    </div>
    <div class="mt-4 overflow-auto " :style="`height:` + cardHeight + `px;max-height:` + cardHeight + `px;overflow: auto; !important`">
      <div class="flex items-center flex-wrap py-2">
        <div class="w-44">
          <div class="hoverCard cust_card">
              <div class="heading-3 text-text1">{{custDetails.jobCount}} Job{{custDetails.jobCount > 0 ? 's' : ''}}</div>
          </div>
        </div>
        <div class="w-44 ml-4">
          <div class="hoverCard cust_card">
              <div class="heading-3 text-text1">{{custDetails.invoiceCount}} Invoice{{custDetails.invoiceCount > 0 ? 's' : ''}}</div>
          </div>
        </div>
        <div class="w-44 ml-4">
          <div class="hoverCard cust_card">
              <div class="heading-3 text-text1">{{custDetails.paymentCount}} Payment{{custDetails.paymentCount > 0 ? 's' : ''}}</div>
          </div>
        </div>
        <div class="w-44 ml-4">
          <div class="hoverCard cust_card">
              <div class="heading-3 text-text1">{{custDetails.quoteCount}} Quote{{custDetails.quoteCount > 0 ? 's' : ''}}</div>
          </div>
        </div>
      </div>
      <div class="py-2" v-if="custDetails.hotNotes !== ''">
        <div class="card bg-white rounded-xl p-4 my-2" v-if="custDetails.hotNotes !== ''">
          <div class="text-gray3 font-bold">Hot Note </div>
          <div class="text-text1 heading-5">{{custDetails.hotNotes}}</div>
        </div>
      </div>
      <div>
        <div class="lg:grid grid-cols-3 gap-4">
          <div>
            <div class="card bg-white rounded-xl p-4 mb-4">
              <div class="flex justify-between items-center">
                <div>
                  <div class="font-bold heading-2" :style="`color: ${custDetails.statusColor};`"> {{custDetails.statusName}}</div>
                  <div class="heading-5 text-gray4">Added on {{custDetails.addedDate | formatDateTimeLead}}</div>
                </div>
              </div>
            </div>
            <div class="card bg-white rounded-xl p-4 mb-4">
              <p class=" text-gray3 font-bold mb-4">Contacts</p>
                  <div v-for="(item, index) in custDetails.contacts" :key="index">
                      <div class="flex justify-between items-center mb-4">
                          <div>
                              <span class="text-gray2"><i class="fa-solid fa-user w-8"></i>:</span>
                              <span class="mb-4 font-bold text-text2"> {{item.firstName}} {{item.lastName}} <span class="heading-7 text-gray4" v-if="item.isPrimary">(Primary)</span></span>
                          </div>
                      </div>
                      <div class="mb-4" v-if="item.emailAddress !== ''">
                          <span class="text-gray2"><i class="fa-solid fa-envelope w-8"></i>:</span>
                          <span class="mb-4  text-text2"> {{item.emailAddress}}</span>
                      </div>
                      <div class="mb-4 flex whitespace-nowrap">
                          <span class="text-gray2"><i class="fa-solid fa-phone w-8"></i>:</span>
                          <p class=" flex flex-wrap  text-text1">
                              <span class="pl-1">
                                  <span v-if="item.phoneNumber !== ''" class="text-gray2">P</span> {{item.phoneNumber| phone}} 
                                  <span class="text-gray2" v-if="item.otherNumber !== ''">,</span>
                              </span>
                              <span v-if="item.otherNumber !== ''" class="pl-1">
                                  <span class="text-gray2"> O</span> {{item.otherNumber| phone}}
                              </span>
                              <span class="text-gray2" v-if="item.otherNumber === '' && item.phoneNumber === ''" >-</span>
                          </p>
                      </div>
                      <div class="divider my-4"  v-if="index < custDetails.contacts.length - 1"></div>
                  </div>
            </div>
          </div>
            <div class=" col-span-2 mb-10">
              <div class=" col-span-2 mb-10" v-if="tabTitle.length > 0">
                <div class="card bg-white rounded-xl p-4 mb-4">
                  <div>
                    <div class="mb-4">
                     <TabCompo :tabsList="tabTitle" />
                    </div>
                    <div v-if="selectedName === 'Jobs'">
                     <JobList :orgId="custDetails.organizationBasicDetail.organizationId" :customerId="parseInt(this.$route.params.customerId)" />
                    </div>
                    <div v-if="selectedName === 'Quotation'">
                     <QuoteList :orgId="custDetails.organizationBasicDetail.organizationId" :customerId="parseInt(this.$route.params.customerId)"/>
                    </div>
                    <div v-if="selectedName === 'Tasks'">
                     <TaskList :orgId="custDetails.organizationBasicDetail.organizationId" :customerId="parseInt(this.$route.params.customerId)"/>
                    </div>
                    <div v-if="selectedName === 'Request'">
                     <ReqList :orgId="custDetails.organizationBasicDetail.organizationId" :customerId="parseInt(this.$route.params.customerId)"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card bg-white rounded-xl p-4 mb-4">
                <div class="flex justify-between items-center">
                    <p class=" text-gray3 font-bold">Address</p>
                </div>
                <div class="mt-4" v-if="custDetails.addressList !== null">
                  <div v-for="(item, index) in custDetails.addressList" :key="index + 'd'" class="mb-4">
                    <div class="text-gray4 heading-6" v-if="item.addressTitle !== ''">{{ item.addressTitle }}:</div>
                    <div class="flex whitespace-nowrap justify-between">
                      <div class="flex items-start">
                        <div>
                          <span @click="openMap(item.latitude, item.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2">:</span></span>
                        </div>
                        <div class="pl-1 relative">
                            <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap cursor-pointer" @click="item.isOpen = !item.isOpen">
                            {{item.fullAddress}}
                            </p>
                            <div v-if="item.isOpen">
                              <p  class="text-text1 heading-6">
                                <span v-if="item.houseNo !== ''">#: {{item.houseNo}}</span>
                                <span v-if="item.houseNo !== ''"> (Floor: {{item.floorNumber}})</span>
                              </p>
                              <p v-if="item.buildingName!== ''" class="text-text1 ">Bldg: {{item.buildingName}}</p>
                              <p v-if="item.directionNote!== ''" class="text-text1 whitespace-pre-wrap">Directions: {{item.directionNote}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="flex items-start" v-if="item.houseNo !== '' || item.buildingName!== '' || item.directionNote!== '' || item.floorNumber !== ''">
                          <span @click="item.isOpen = !item.isOpen" v-if="item.isOpen" class="text-primary cursor-pointer"><i class="fas fa-chevron-up"></i></span>
                          <p @click="item.isOpen = !item.isOpen" v-if="!item.isOpen" class="text-primary cursor-pointer"><i class="fas fa-chevron-down"></i></p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TabCompo from '@/View/components/tabs.vue'
import JobList from '@/View/Admin/customer/components/JobList.vue'
import QuoteList from '@/View/Admin/customer/components/quoteList.vue'
import TaskList from '@/View/Admin/customer/components/TaskList.vue'
import ReqList from '@/View/Admin/customer/components/requestList.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
export default {
  name: "customer",
  components: {
    TabCompo,
    JobList,
    QuoteList,
    TaskList,
    ReqList,
  },
  data() {
    return {
      selectedName: 'Jobs',
      seletedAddress: {},
      editJobAddress: false,
      isDelConfirm: false,
      statusList: [],
      changeStatusDialog: false,
      activeIndex: 0,
      contactDetailForUpdate: null,
      deleteSelectId: null,
      updatesContact: false,
      delteAddressConfirm: false,
      updateCompanyName: false,
      addAddress: false,
      custDetails: null,
      tabTitle: [],
      tabs: [
        {name: 'Jobs', active: true},
        {name: 'Quotation', active: false},
        {name: 'Tasks', active: false},
        {name: 'Request', active: false}
      ]
    };
  },
  created() {},

  mounted() {
    document.title = 'Lead Detail'
    for (let index = 0; index < this.tabs.length; index++) {
      this.tabTitle.push(this.tabs[index])
    }
    if (this.tabTitle.length > 0) {
      this.tabTitle[0].active = true
      this.selectedName = this.tabTitle[0].name
    }
    if (parseInt(this.$route.query.activeTab) >= 0) {
      this.tabsSet(parseInt(this.$route.query.activeTab))
      setTimeout(() => {
        this.$router.replace({'query': null});
      }, 500);
    }
    this.$root.$on('setNewTabActive', (index) => {
        this.tabsSet(index)
    })
    this.cardHeight = window.innerHeight - 170
    this.getCustDetails();
  },
  beforeDestroy() {
    this.$root.$off("setNewTabActive");
  },
  methods: {
    tabsSet (index) {
    this.tabTitle.forEach((element, i) => {
        if (index === i) {
            element.active = true
            this.selectedName = element.name
            this.activeIndex = i
        } else {
            element.active = false
        }
    });
    },
    getCustDetails() {
      console.log("call", this.$route);
      console.log("call", this.$route.params);
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
      ADMINAPI.getLeadDetail(
        parseInt(this.$route.params.customerId),
        (response) => {
          let custDetails = response.Data;
          let addInfo = custDetails.addressList === null ? [] : custDetails.addressList
          for (let index = 0; index < addInfo.length; index++) {
            addInfo[index].isOpen = false
          }
          this.custDetails = custDetails
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
        }
      );
    },
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    }
  }
};
</script>
<style scoped>
</style>